@import url("https://fonts.googleapis.com/css?family=Fira+Code|Work+Sans:300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Solway:300,400,500,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Fugaz+One&display=swap");

.fugaz {
  font-family: "Fugaz One", sans-serif !important;
}

main {
  font-family: system-ui, sans-serif !important;

  tbody.v-align-center {
    td {
      vertical-align: middle;
    }
  }

  .text-shadow-light {
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  }
  .text-shadow {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
  }

  .fira {
    font-family: "Fira Code", monospace !important;
  }

  .oswald {
    font-family: "Oswald", sans-serif !important;
    font-weight: 400 !important;
  }

  main #content {
    min-height: 100vh;
    position: relative;
  }

  .icon-button-delete {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
}
